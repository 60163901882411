import React, { Component } from 'react';
import style from './tab.module.scss';
import SlideToggle from 'react-slide-toggle';

const noop = () => {};
export class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teaserHeight: 0
    };
  }

  componentDidMount() {
    this.setState({
      teaserHeight: this.teaserElement.clientHeight
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      teaserHeight: this.teaserElement.clientHeight
    });
  }

  render() {
    const { title, colorCode, textHighlightColor } = this.props;
    const hasDownload = false;
    // typeof this.props.download !== 'undefined' &&
    // this.props.download !== null &&
    // typeof this.props.download.file === 'string';

    return (
      <SlideToggle
        collapsed={true}
        duration={800}
        noDisplayStyle
        onExpanded={this.props.onExpanded || noop}
        onCollapsed={this.props.onCollapsed || noop}
        render={({ onToggle, setCollapsibleElement, toggleState, progress }) => {
          const children = this.props.children(onToggle);

          return (
            <div className={style.tab}>
              <h2
                className={style.tabHeader}
                style={{
                  backgroundColor: colorCode,
                  color: textHighlightColor || '#000'
                }}
                onClick={e => {
                  if (hasDownload && e.target && e.target.alt === 'Download') return;
                  onToggle(e);
                }}
              >
                <span
                  className={style.tabStatusIcon}
                  style={{
                    transform: `rotate(${Math.round(360 * (-1 + progress))}deg)`,
                    animation: progress < 1 && progress > 0 ? 'none' : null
                  }}
                >
                  {progress > 0.5 ? '-' : '+'}
                </span>
                {title}
              </h2>
              <div className={style.tabInner}>
                <div
                  ref={teaser => (this.teaserElement = teaser)}
                  style={{
                    marginTop: `${Math.round(-1 * this.state.teaserHeight * (1 * progress))}px`,
                    opacity: `${Math.round(1 - progress)}`,
                    transition: 'opacity 1s ease'
                  }}
                >
                  {children[0]}
                </div>
                <div style={{ overflow: 'hidden' }} ref={setCollapsibleElement}>
                  <div
                    style={{
                      transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
                    }}
                  >
                    {children.map((child, i) => {
                      // Ignore the first child
                      if (i < 1) return null;
                      return child;
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}
